/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
.desk {
  max-width: 96%;
}
#home {
  width: 226px;
  margin-top: 11px;
  margin-bottom: 11px;
}
@media only screen and (max-width: 1100px) {
  .section--footer {
    font-size: 14px;
  }
  #services {
    font-size: 12px;
    font-size: 1.2rem;
  }
}
.maincontent {
  width: 65.92592593%;
}
.sidecontent {
  float: right;
  width: 31.85185185%;
  margin-top: 0;
}
@media only screen and (max-width: 1023px) {
  .north h2 {
    font-size: 36px;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1.63043478%;
  margin-left: 1.63043478%;
}
.area .part,
.area > .grid table {
  margin-right: 1.63043478%;
  margin-left: 1.63043478%;
  width: 96.73913043%;
}
.area .tiny {
  width: 46.73913043%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 3.26086957%;
  margin-left: 3.26086957%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 93.47826087%;
}
.area > .slim .tiny {
  width: 93.47826087%;
}
.side {
  width: 100%;
}
.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side h2,
.side .foot {
  margin-right: 3.48837209%;
  margin-left: 3.48837209%;
}
.side .part,
.side > .grid table {
  margin-right: 3.48837209%;
  margin-left: 3.48837209%;
  width: 93.02325581%;
}
.side > .slim .part,
.side > .slim.grid table {
  width: 93.02325581%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
div.main {
  width: 103.37078652%;
  margin-left: -1.68539326%;
}
div.main div.seam.slim:first-child + .slim {
  margin-top: 30px;
}
div.north {
  width: 860px;
  position: absolute;
  left: 2%;
  max-width: 96%;
  bottom: 8.75621891%;
  margin: 0;
}
div.main div.seam {
  margin-left: 1.63043478%;
  margin-right: 1.63043478%;
}
div.main div.seam.wide {
  width: 96.73913043%;
}
div.main div.seam.wide div.head h2,
div.main div.seam.wide div.part,
div.main div.seam.wide div.foot {
  margin-left: 2.24719101%;
  margin-right: 2.24719101%;
}
div.main div.seam.wide div.tall {
  width: 95.50561798%;
}
div.main div.seam.wide div.tiny {
  width: 45.50561798%;
}
div.main div.seam.slim {
  width: 46.73913043%;
}
div.main div.seam.slim div.head h2,
div.main div.seam.slim div.part,
div.main div.seam.slim div.foot {
  margin-left: 4.65116279%;
  margin-right: 4.65116279%;
}
div.main div.seam.slim div.part {
  width: 90.69767442%;
}
div.side div.seam {
  margin-left: 3.48837209%;
  margin-right: 3.48837209%;
  width: 93.02325581%;
}
div.side div.seam div.head h2,
div.side div.seam div.part,
div.side div.seam div.foot {
  margin-left: 5%;
  margin-right: 5%;
}
div.side div.seam div.part {
  width: 90%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-medium.css.map */